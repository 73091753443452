<template>
  <div class="survey_dashboard_container">
    <Statistic group-name="mbsb"></Statistic>
    <div>
      <div class="survey_dashboard-main">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div style="padding-bottom: 10px">
                <p style="font-size: 12px">Эх сурвалж: Хөдөлмөрийн зах зээлийн мэдээллийн сан 2022 оны байдлаар.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <iframe
                src="https://analytic.edu-track.gov.mn/superset/dashboard/2/?standalone=true&native_filters=%28NATIVE_FILTER--FMIPVLg7%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER--FMIPVLg7%2CownState%3A%28%29%29%2CNATIVE_FILTER-751VR8oVZ%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-751VR8oVZ%2CownState%3A%28%29%29%2CNATIVE_FILTER-92yZk1k4u%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-92yZk1k4u%2CownState%3A%28%29%29%2CNATIVE_FILTER-DgI9gX8Sz%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-DgI9gX8Sz%2CownState%3A%28%29%29%2CNATIVE_FILTER-FHypA0xyq%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-FHypA0xyq%2CownState%3A%28%29%29%2CNATIVE_FILTER-HUwxqk3aX%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-HUwxqk3aX%2CownState%3A%28%29%29%2CNATIVE_FILTER-ItaFkh9Qj%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-ItaFkh9Qj%2CownState%3A%28%29%29%2CNATIVE_FILTER-JHALW_bm9%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-JHALW_bm9%2CownState%3A%28%29%29%2CNATIVE_FILTER-JL5Fzy3Qz%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-JL5Fzy3Qz%2CownState%3A%28%29%29%2CNATIVE_FILTER-LDfjUiWC8%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-LDfjUiWC8%2CownState%3A%28%29%29%2CNATIVE_FILTER-WgMTmfNnl%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-WgMTmfNnl%2CownState%3A%28%29%29%2CNATIVE_FILTER-kpWKL7dFm%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-kpWKL7dFm%2CownState%3A%28%29%29%2CNATIVE_FILTER-xc1R2YZWP%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-xc1R2YZWP%2CownState%3A%28%29%29%2CNATIVE_FILTER-y3sdtEGIv%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-y3sdtEGIv%2CownState%3A%28%29%29%29"
                frameborder="0"
                style="border: none; width: 100%; height: 100vh"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <about></about>
  </div>
</template>

<script>
const Statistic = () => import(/* webpackChunkName: "statistic" */ './statistic');
const about = () => import(/* webpackChunkName: "p_about" */ '../section/p_about')
// import Statistic from "./statistic";
export default {
    name: "hudulmur_erhlelt.vue",
    components: {
        "Statistic": Statistic,
        "about": about,
    }
}
</script>

<style scoped>

</style>
